<template>
<div class="flex justify-center  w-full">
    <div class="credit-view   mw-1920 w-full">
        <div class="credit-top-view pt-200">
            <h1 class="top-h1" data-aos="fade-up">Оформлення кредиту</h1>
            <p class="top-p mt-20px" data-aos="fade-up">Декілька простих кроків</p>
            <div class="credit-top-bg">
                <img src="@/assets/img/stockbg.svg" alt="">
            </div>
        </div>
        <div class="steps">
            <Step v-model="activeName" />
        </div>
        <div class="flex justify-center fw-w" v-if="activeName==1">
            <div class="credit-car pt-0 pb-0 mw-1920 w-full">
                <div class="credit-car-items">
                     <div class="credit-car-img">
                         <img src="@/assets/img/big-car.svg" alt="">
                     </div>
                     <div class="credit-car-description">
                         <h1>Комбайн AX-32233</h1>
                         <h2>Від 40 000 грн / міс</h2>
                         <div class="car-description-item" >
                             <p>Вартість, грн</p>
                             <p class="op8">3 650 000</p>
                         </div>
                          <div class="car-description-item" >
                             <p>Виробник</p>
                             <p class="op8">RIA</p>
                         </div>
                          <div class="car-description-item" >
                             <p>Країна виробника</p>
                             <p class="op8">США</p>
                         </div>

                         <div class="credit-duration" v-click-outside="PeriodHide">
                             <button @click="period=!period">
                                 <p :class="period?'disabled_button':''">{{creditCount.month? `${creditCount.month} місяць`:'Період *'}} </p>
                                 <img :class="period?'rotate':''" src="@/assets/icons/down.svg" alt="">
                             </button>
                             <el-collapse-transition>
                                 <div class="drop-down-period" v-show="period">
                                     <div class="drop-down-period-content">
                                        <div class="drop-down-period-items">
                                            <div class="drop-down-period-item" @click="creditCount.month=6; period=false"> <p>6 місяць</p></div>
                                            <div class="drop-down-period-item" @click="creditCount.month=12; period=false"> <p>12 місяць</p></div>
                                            <div class="drop-down-period-item" @click="creditCount.month=24; period=false"> <p>24 місяць</p></div>
                                            <div class="drop-down-period-item" @click="creditCount.month=36; period=false"> <p>36 місяць</p></div>
                                            <div class="drop-down-period-item" @click="creditCount.month=48; period=false"> <p>48 місяць</p></div>
                                            <div class="drop-down-period-item" @click="creditCount.month=60; period=false"> <p>60 місяць</p></div>
                                        </div>
                                     </div>
                                 </div>
                                 </el-collapse-transition>
                         </div>
                     </div>
                </div>
            </div>
            <div class="w-full flex justify-center credit-next">
                <button class="button" :disabled="!creditCount.month" :class="!creditCount.month?'disabled_button':''" @click="activeName=2; ScrollTop()">Продовжити</button>
            </div>
        </div>
        <div class="flex justify-center" v-if="activeName==2">
            <div class="credit-info w-full">
                <div class="credit-info-texts">
                    <h1 data-aos="fade-right">Іформація про кредит</h1>
                    <p data-aos="fade-left">Vel cras ipsum ac congue vulputate nulla vivamus commodo. Egestas nisi, enim turpis ut amet sollicitudin. Ultricies eu augue donec justo, elit gravida pharetra hendrerit lorem. Fames posuere viverra amet est volutpat lacus, suspendisse pellentesque ullamcorper. Lobortis lacinia condimentum hendrerit magnis non consectetur diam. Enim neque amet convallis enim metus ut.</p>
                    <div class="flex fw-w mt-20">
                        <button class="button gray mr-20 mt-20">Як погасити кредит ?</button>
                        <button class="button blue mt-20">План оплати НБУ</button>
                    </div>
                </div>
                <div class="cretit-table-top">
                    <p data-aos="fade-left"> <span>Сума:</span> {{creditCount.count}} грн </p>
                    <p data-aos="fade-left"> <span>Начислення:</span> {{creditCount.accrual}} грн </p>
                    <p data-aos="fade-left"> <span>Погашення:</span> {{creditCount.redemption}} грн </p>
                </div>
                <div class="credit-table desktop">
                    <h1>План оплати</h1>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>№</th>
                                <th>Дата</th>
                                <th>До сплати</th>
                                <th>Залишки</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,i) in monthlyPayTable" :key="i">
                                <td class="credit-table-hide-td"></td>
                                <td>{{i+1}}</td>
                                <td> {{item.date}} </td>
                                <td> {{item.amount}} грн</td>
                                <td class="c-blue"> {{item.leftover}} грн</td>
                                <td class="credit-table-hide-td"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="credit-table-phone phone">
                    <h1>План оплати</h1>
                    <div class="credit-table-phone-tr " v-for="(item,i) in monthlyPayTable" :key="i" :class="i%2==0?'bg-gray':''" :data-aos="i%2==1?'fade-right':'fade-left'">
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head">№</p>
                            <p>{{i+1}}</p>
                        </div>
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head">Дата</p>
                            <p>{{item.date}}</p>
                        </div>
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head"> {{$t('do_credit')}} </p>
                            <p>{{item.amount}} грн</p>
                        </div>
                        <div class="credit-table-phone-td">
                            <p class="credit-table-phone-td-head"> {{$t('leftovers')}} </p>
                            <p class="c-blue">{{item.leftover}} грн</p>
                        </div>
                    </div>
                </div>
                <div class="w-full flex justify-center credit-next-button">
                    <button class="button" @click="Check()">Продовжити</button>
                </div>
            </div>

        </div>
        <div class="flex justify-center" v-if="activeName==3">
            <div class="credit-input mw-1920">
                <div class="credit-input-texts">
                    <h1 data-aos="fade-up">Іформація про кредит</h1>
                    <p class="c-blue" data-aos="fade-up"> {{$t('fiz_osoba')}} </p>
                </div>

                <div class="credit-input-items">
                    <div class="credit-input-item" data-aos="fade-up">
                        <input type="text" placeholder="Петр">
                        <input type="text" placeholder="Петров">
                        <input type="text" placeholder="Петрович">
                        <input type="text" placeholder="Email">
                        <input type="text" placeholder="+ 380 099 99 99">
                        <input type="text" placeholder="20/10/2020">
                    </div>
                    <div class="credit-input-item" data-aos="fade-up">
                        <input type="text" placeholder="JJ8588585">
                        <input type="text" placeholder="Ким виданий паспорт">
                        <input type="text" placeholder="17/02/12">
                        <input type="text" placeholder="Ідентифікаційний номер">
                        <input type="text" placeholder="Київська область, місто Київ, вулиця Київська">
                        <input type="text" placeholder="Будинок 8, квартира 15">
                    </div>
                </div>
                <div class="w-full flex justify-center credit-next-button">
                    <button class="button" @click="activeName=4; ScrollTop()">Продовжити</button>
                </div>
            </div>
        </div>
        <div class="flex justify-center" v-if="activeName==4">
            <div class="credit-info-last mw-1920">

                <div class="credit-last-items">
                    <div class="credit-last-item">
                        <div class="credit-last-texts">
                            <h1 data-aos="fade-right">Дякуємо за звернення!</h1>
                            <p data-aos="fade-right">Дорогий клієнт, дякуємо за заявку на кредит, скоро наші спеціалісти з Вами зв’яжуться.</p>
                        </div>
                        <div class="credit-last-phone">
                            <h1 data-aos="fade-right">+ 380 87 676 87 67</h1>
                            <p data-aos="fade-right">Безкоштовно з будь-яких операторів</p>
                        </div>
                        <div class="credit-last-email">
                            <p data-aos="fade-right">Служба підтримки: <span>email@gmail.com</span> </p>
                            <p data-aos="fade-right">Служба підтримки: <span>email@gmail.com</span> </p>
                            <p data-aos="fade-right">Служба підтримки: <span>email@gmail.com</span> </p>
                        </div>
                        <div class="credit-last-addres">
                            <p data-aos="fade-right">Україна, м. Київ, вул. Вадима Гетьмана, 20</p>
                        </div>
                    </div>
                    <div class="credit-last-item">
                        <div class="credit-last-img">
                            <img src="@/assets/img/big-a.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script> 
import Step from '../../components/steps.vue'
import ClickOutside from 'vue-click-outside'
export default {
    name: 'credit',
    components: { 
        Step
    },
    computed:{
        me() {
            return this.$store.state.me
        }
    },

    data() {
        return {
            a: 10,
            period:false,
            kredit_type: 'pledge',
            activeName: 1,
            CreditInputs: {
                first_name: "Петр",
                last_name: 'Петров',
                mid_name: 'Петрович',
                email: 'Email',
                phone: '+ 380 099 99 99',
                date: '20/10/2020',
                passport: 'JJ8588585',
                passport_from: 'Ким виданий паспорт',
                passpodt_date: '17/02/12',
                id_number: 'Ідентифікаційний номер',
                district: 'Київська область, місто Київ, вулиця Київська',
                adress: 'Будинок 8, квартира 15'
            },
            creditCount: {
                count: 3650000,
                accrual: 0,
                redemption: 0,
                month: 0,
                lastDate: '--.--.--',
                percenti: 0

            },
            monthlyPayTable: [],
            disabled: false

        }
    },

    methods: {
        ScrollTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },
        Check() {
            if (this.me.id) {
                this.activeName = 3;
                this.ScrollTop()
            } else {
                this.$router.push('/login');
                localStorage.count = this.creditCount.count
                localStorage.id = this.$route.params.id
                localStorage.type = 'leasing'
                localStorage.month = this.creditCount.month
            }

        },
        countDate(month) {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            mm = parseInt(mm)
            yyyy = parseInt(yyyy)
            var yyyy = today.getFullYear();
            var lastyear = parseInt(month / 12)
            var lastmonth = month - lastyear * 12
            yyyy = yyyy + parseInt((mm + lastmonth) / 12) + lastyear
            mm = (mm + lastmonth) % 12
            mm = mm == 0 ? '01' : mm < 10 ? '0' + mm : mm
            this.creditCount.lastDate = dd + '.' + mm + '.' + yyyy;

        },
        countCredit(cost, month) {

            if (month > 11) {
                this.creditCount.percenti = 10
            } else {
                this.creditCount.percenti = 8
            }
            var CostAccrual = 0

            for (let i = 1; i <= month; i++) {
                CostAccrual = parseInt(CostAccrual) + parseInt((parseInt(cost) * parseInt(this.creditCount.percenti)) / 100)
            }

            this.creditCount.redemption = parseInt(cost) + parseInt(CostAccrual)
            this.creditCount.accrual = CostAccrual

            var CreditTable = []
            var creditMonthPay = parseInt(this.creditCount.redemption / month)

            for (let i = 1; i <= month; i++) {
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                mm = parseInt(mm)
                yyyy = parseInt(yyyy)
                var yyyy = today.getFullYear();
                var lastyear = parseInt(i / 12)
                var lastmonth = i - lastyear * 12
                yyyy = yyyy + parseInt((mm + lastmonth) / 12) + lastyear
                mm = (mm + lastmonth) % 12
                mm = mm == 0 ? '01' : mm < 10 ? '0' + mm : mm
                var date = dd + '.' + mm + '.' + yyyy;
                var Leftover = this.creditCount.redemption - creditMonthPay * i
                CreditTable.push({
                    date: date,
                    amount: creditMonthPay,
                    leftover: Leftover
                })
            }
            this.monthlyPayTable = CreditTable

        },
        PeriodHide(){
            this.period=false
        }
    },
    watch: {
        'creditCount.month'(val) {
            this.countCredit(this.creditCount.count, val)
            this.countDate(val)
        },
        'creditCount.count'(val) {
            this.countCredit(val, this.creditCount.month)
        }
    },
    mounted() {
        this.creditCount.count=this.$route.query.amount?this.$route.query.amount:3650000
        this.creditCount.month=this.$route.query.month? this.$route.query.month:0
        this.activeName=this.$route.query.step? this.$route.query.step:1
        this.countCredit(this.creditCount.count, this.creditCount.month)
        this.countDate(1)
    },
    directives: {
    ClickOutside
  },

}
</script>

<style lang="scss">
.credit-car{
    padding: 0px 80px;
}
.credit-view{
    background: var(--white-bg)
}
.credit-car-items{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 50px;
    .credit-car-img{
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 40px;
        img{
            width: 100%;
        }
    }
    .credit-car-description{
        width: 50%;
        padding: 40px;
        h1{
            font-family: Stem-Medium;
            font-size: 24px;
            line-height: 1.75rem; 
            color: var(--color-blue);
        }
        h2{
            font-family: Stem-Medium;
            font-size: 1.125rem;
            line-height: 1.25rem; 
            margin: 20px 0px;
            color: var(--other-black);
        }

        .car-description-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 400px;
            padding: 10px 0px;
            p{
                font-family: Stem-Regular;
                font-size: 1.125rem;
                line-height: 1.25rem;
                display: flex;
                align-items: center;
                color: var(--other-black);
            }
        }
        .credit-duration{
            margin-top: 30px;
            max-width: 200px;
            position: relative;
            z-index: 10;
             
            button{
                padding: 14px 20px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #00B5EC;
                border-radius: 4px; 
                cursor: pointer;
                outline: none;
                min-width: 200px;
                position: relative;
                border: none;
                img{
                    margin-left: 20px;
                    transition: all 0.3s ease-in;
                    filter: invert(99%) sepia(97%) saturate(0%) hue-rotate(326deg) brightness(115%) contrast(100%);
                }
                .rotate{
                    transform: rotate(180deg);
                }
                p{
                    font-family: Stem-Medium;
                    font-size: 1.125rem;
                    line-height: 1.25rem; 
                    color: var(--white-color);
                }
            }
            
            .drop-down-period{
                position: absolute; 
                background: #00B5EC;
                min-width: 200px;
                width: 100%; 
                border:1px solid  #00B5EC;
                transition:  all 0.3 ease-in;
                left: 0;
                top: 90%;
                border-radius: 0px 0px 4px 4px;  
                .drop-down-period-content{
                    padding: 10px 5px 10px 0px; 
                    height: 220px;
                }
                .drop-down-period-items{
                    max-height: 200px;
                    height: 200px;
                    overflow-y: scroll; 
                }
                .drop-down-period-items::-webkit-scrollbar-track{ 
                    
                    background: #58D8FF; 
                    border-radius: 5px;
                }

                .drop-down-period-items::-webkit-scrollbar
                {
                    width: 5px;
                    border-radius: 5px;
                    background: #58D8FF; 
                    
                }

                .drop-down-period-items::-webkit-scrollbar-thumb
                {
                    background-color: #fff;
                    border-radius: 5px;
                }
                .drop-down-period-item{ 
                    min-height: 50px;
                    height: 50px;
                    text-align: left;
                    padding: 14px 20px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    p{
                        font-family: Stem-Medium;
                        font-size: 1.125rem;
                        line-height: 1.25rem; 
                        color: #FFFFFF;
                    }
                } 
                .drop-down-period-item:hover{ 
                    background: rgba(255, 255, 255, 0.11);
                }
            }
        }
    }
}


@media screen and(max-width:1100px) {
    .credit-car{
    padding: 0px 0px;
}
.credit-car-items{ 
    .credit-car-img{
        width: 50%;
        display: flex;
        justify-content: center;
        padding: 0px 20px 20px 20px;
        img{
            width: 100%;
        }
    }
    .credit-car-description{
        width: 50%;
        padding:20px 20px;  
    }
}
}
@media screen and(max-width:900px) {
.credit-car-items{ 
    .credit-car-img{
        width: 100%; 
        img{
            width: 80%;
        }
        
    }
    .credit-car-description{
        width: 100%; 
        h1{
            font-size: 20px;
            line-height: 1.5rem;
        }
        h2{
             
            margin: 10px 0px 20px 0px; 
        }

        .car-description-item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 400px;
            padding: 10px 0px;
            p{
                font-size: 1rem;
                line-height: 1.125rem;
            }
        }
        .credit-duration{
            margin-top: 30px;
            button{
                padding: 14px 20px;
                max-width: 400px;
                width: 100%;
                 
            }
            .drop-down-period{ 
                .drop-down-period-content{
                    padding: 5px 5px 10px 0px; 
                    height: 180px;
                }
                .drop-down-period-items{
                    max-height: 160px;
                    height: 160px; 
                }
                 
                .drop-down-period-item{ 
                    min-height: 40px;
                    height: 40px; 
                     
                }  
            }
        }
    }
}

}

</style>
